import { Route, Routes, useLocation } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';
import PageNotFoundView from './PageNotFoundView';
import trackPathForAnalytics from './GAAnalytics';
import NewsletterIssue from './components/NewsletterIssue';
import NewsletterPage from './components/NewsletterPage';
import { ApiProvider } from './utils/apiContext';
import UnsubscribePage from './components/UnsubscribePage';
import Landing from './components/Landing';
import ToolMain from './components/WebTool/ToolMain';

function App() {
  const [baseURL, setBaseURL] = useState(null);

  useEffect(() => {
    const url = window.location.href;
    if (url.includes("/dev/")) {
      setBaseURL("https://us-central1-ai-podcast-host-dev.cloudfunctions.net/aiph_api_v3");
    } else {
      setBaseURL("https://us-central1-ai-podcast-host.cloudfunctions.net/aiph_api_v3");
    }
  }, []);

  const { pathname, search } = useLocation();

  const analytics = useCallback(() => {
    trackPathForAnalytics({ path: pathname, search: search, title: pathname.split("/")[1] });
  }, [pathname, search]);

  useEffect(() => {
    analytics();
  }, [analytics]);

  return (
    <ApiProvider baseURL={baseURL}>
      <Routes>
        <Route path="/" element={<Landing baseURL={baseURL} />} />

        <Route path="/no-page-found" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/about" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/about/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/about" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/about/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/faq" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/faq/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/faq" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/faq/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/contact" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/contact/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/contact" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/contact/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/contactus" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/contactus/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/contactus" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/contactus/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/support" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/support/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/support" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/support/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/help" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/help/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/help" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/help/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/pricing" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/pricing/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/pricing" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/pricing/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/features" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/features/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/features" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/features/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/products" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/products/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/products" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/products/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/services" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/services/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/services" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/services/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/blog" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/blog/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/blog" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/blog/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/news" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/news/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/news" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/news/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/careers" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/careers/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/careers" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/careers/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/jobs" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/jobs/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/jobs" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/jobs/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/team" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/team/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/team" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/team/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/privacy" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/privacy/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/privacy" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/privacy/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/terms" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/terms/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/terms" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/terms/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/login" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/login/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/login" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/login/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/signin" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/signin/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/signin" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/signin/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/signup" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/signup/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/signup" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/signup/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/register" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/register/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/register" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/register/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/account" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/account/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/account" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/account/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/profile" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/profile/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/profile" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/profile/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/settings" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/settings/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/settings" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/settings/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/dashboard" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dashboard/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/dashboard" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/dashboard/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/home" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/home/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/home" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/home/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/search" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/search/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/search" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/search/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/sitemap" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/sitemap/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/sitemap" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/sitemap/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/feedback" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/feedback/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/feedback" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/feedback/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/partners" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/partners/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/partners" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/partners/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/affiliates" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/affiliates/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/affiliates" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/affiliates/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/resources" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/resources/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/resources" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/resources/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/downloads" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/downloads/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/downloads" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/downloads/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/docs" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/docs/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/docs" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/docs/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/documentation" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/documentation/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/documentation" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/documentation/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/api" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/api/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/api" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/api/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/developers" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/developers/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/developers" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/developers/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/status" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/status/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/status" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/status/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/updates" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/updates/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/updates" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/updates/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/subscribe" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/subscribe/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/subscribe" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/subscribe/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/press" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/press/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/press" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/press/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/media" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/media/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/media" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/media/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/events" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/events/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/events" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/events/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/webinars" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/webinars/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/webinars" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/webinars/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/tutorials" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/tutorials/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/tutorials" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/tutorials/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/how-to" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/how-to/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/how-to" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/how-to/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/getting-started" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/getting-started/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/getting-started" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/getting-started/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/community" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/community/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/community" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/community/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/forum" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/forum/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/forum" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/forum/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/testimonials" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/testimonials/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/testimonials" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/testimonials/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/reviews" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/reviews/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/reviews" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/reviews/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/case-studies" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/case-studies/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/case-studies" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/case-studies/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/portfolio" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/portfolio/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/portfolio" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/portfolio/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/projects" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/projects/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/projects" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/projects/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/clients" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/clients/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/clients" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/clients/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/locations" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/locations/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/locations" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/locations/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/store" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/store/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/store" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/store/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/shop" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/shop/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/shop" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/shop/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/cart" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/cart/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/cart" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/cart/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/checkout" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/checkout/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/checkout" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/checkout/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/order" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/order/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/order" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/order/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/track" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/track/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/track" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/track/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/returns" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/returns/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/returns" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/returns/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/shipping" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/shipping/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/shipping" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/shipping/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/billing" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/billing/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/billing" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/billing/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/payment" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/payment/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/payment" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/payment/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/plans" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/plans/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/plans" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/plans/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/enterprise" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/enterprise/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/enterprise" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/enterprise/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/solutions" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/solutions/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/solutions" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/solutions/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/industries" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/industries/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/industries" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/industries/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/company" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/company/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/company" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/company/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/mission" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/mission/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/mission" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/mission/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/vision" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/vision/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/vision" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/vision/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/values" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/values/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/values" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/values/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/history" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/history/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/history" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/history/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/leadership" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/leadership/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/leadership" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/leadership/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/investors" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/investors/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/investors" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/investors/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/partnerships" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/partnerships/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/partnerships" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/partnerships/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/legal" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/legal/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/legal" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/legal/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/accessibility" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/accessibility/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/accessibility" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/accessibility/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/security" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/security/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/security" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/security/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/trust" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/trust/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/trust" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/trust/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/compliance" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/compliance/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/compliance" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/compliance/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/sustainability" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/sustainability/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/sustainability" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/sustainability/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/csr" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/csr/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/csr" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/csr/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/diversity" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/diversity/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/diversity" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/diversity/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/inclusion" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/inclusion/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/inclusion" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/inclusion/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/ethics" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/ethics/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/ethics" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/ethics/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/reports" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/reports/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/reports" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/reports/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/annual-report" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/annual-report/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/annual-report" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/annual-report/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/financials" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/financials/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/financials" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/financials/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/awards" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/awards/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/awards" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/awards/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/recognition" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/recognition/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/recognition" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/recognition/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/brand" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/brand/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/brand" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/brand/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/style-guide" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/style-guide/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/style-guide" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/style-guide/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/press-kit" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/press-kit/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/press-kit" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/press-kit/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/beta" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/beta/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/beta" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/beta/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/feedback" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/feedback/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/feedback" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/feedback/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/mobile" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/mobile/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/mobile" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/mobile/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/app" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/app/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/app" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/app/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/ios" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/ios/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/ios" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/ios/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/android" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/android/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/android" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/android/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/trial" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/trial/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/trial" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/trial/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/upgrade" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/upgrade/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/upgrade" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/upgrade/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/cancel" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/cancel/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/cancel" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/cancel/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/referral" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/referral/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/referral" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/referral/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/affiliate-program" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/affiliate-program/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/affiliate-program" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/affiliate-program/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/integrations" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/integrations/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/integrations" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/integrations/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/marketplace" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/marketplace/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/marketplace" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/marketplace/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/partners-portal" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/partners-portal/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/partners-portal" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/partners-portal/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/resellers" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/resellers/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/resellers" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/resellers/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/customer-stories" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/customer-stories/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/customer-stories" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/customer-stories/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/knowledge-base" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/knowledge-base/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/knowledge-base" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/knowledge-base/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/training" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/training/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/training" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/training/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/certification" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/certification/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/certification" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/certification/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/reset-password" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/reset-password/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/reset-password" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/reset-password/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/verify" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/verify/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/verify" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/verify/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/maintenance" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/maintenance/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/maintenance" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/maintenance/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/status-updates" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/status-updates/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/status-updates" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/status-updates/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/roadmap" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/roadmap/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/roadmap" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/roadmap/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/changelog" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/changelog/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/changelog" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/changelog/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/release-notes" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/release-notes/:any" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/release-notes" element={<PageNotFoundView baseURL={baseURL} />} />
        <Route path="/dev/release-notes/:any" element={<PageNotFoundView baseURL={baseURL} />} />

        <Route path="/dev" element={<PageNotFoundView baseURL={baseURL} />} />

        {/* Actual paths that are used */}

        <Route path="/demo" element={<ToolMain baseURL={baseURL} />} />
        <Route path="/demo/:any" element={<ToolMain baseURL={baseURL} />} />
        <Route path="/dev/demo" element={<ToolMain baseURL={baseURL} />} />
        <Route path="/dev/demo/:any" element={<ToolMain baseURL={baseURL} />} />

        <Route path="/unsubscribe" element={<UnsubscribePage baseURL={baseURL} />} />
        <Route path="/unsubscribe/:any" element={<UnsubscribePage baseURL={baseURL} />} />
        <Route path="/dev/unsubscribe" element={<UnsubscribePage baseURL={baseURL} />} />
        <Route path="/dev/unsubscribe/:any" element={<UnsubscribePage baseURL={baseURL} />} />

        <Route path="/newsletter" element={<NewsletterPage baseURL={baseURL} />} />
        <Route path="/newsletter/:any" element={<NewsletterPage baseURL={baseURL} />} />
        <Route path="/dev/newsletter" element={<NewsletterPage baseURL={baseURL} />} />
        <Route path="/dev/newsletter/:any" element={<NewsletterPage baseURL={baseURL} />} />

        <Route path="/issue" element={<NewsletterIssue baseURL={baseURL} />} />
        <Route path="/issue/:any" element={<NewsletterIssue baseURL={baseURL} />} />
        <Route path="/dev/issue" element={<NewsletterIssue baseURL={baseURL} />} />
        <Route path="/dev/issue/:any" element={<NewsletterIssue baseURL={baseURL} />} />

        <Route path="/:newsletterUri" element={<NewsletterPage baseURL={baseURL} />} />
        <Route path="/dev/:newsletterUri" element={<NewsletterPage baseURL={baseURL} />} />
        <Route path="/:newsletterUri/:issueId" element={<NewsletterIssue baseURL={baseURL} />} />
        <Route path="/dev/:newsletterUri/:issueId" element={<NewsletterIssue baseURL={baseURL} />} />
      </Routes>
    </ApiProvider>
  );
}

export default App;