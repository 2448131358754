import React, { useState } from 'react';
import '../styles/SubscriptionModal.css';
import NewsletterLogo from './NewsletterLogo';
import { useApi } from '../utils/apiContext';
import { wait } from '@testing-library/user-event/dist/utils';

const SubscriptionModal = ({ onClose, newsletter }) => {
    const { subscribeToNewsletter } = useApi()
    // State to store the entered email
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    // Function to validate email format
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    // Function to handle subscription logic
    async function subscribe() {
        // Validate email
        if (!validateEmail(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        setError(''); // Clear any previous errors

        try {
            const result = await subscribeToNewsletter(email, newsletter.newsletter_uri);
            if (result) {
                if (result.error) {
                    if (result.error === 'ALREADY_SUBSCRIBED') {
                        setError("You are already subscibed to this newsletter")
                    } else {
                        setError("Failed to subscribe. Try again later")
                    }
                } else {
                    setError("Success")
                    newsletter.subscribers_count += 1
                    await wait(1000)
                    onClose()
                }
            }
        } catch (error) {
            // Handle fetch error
            setError('Failed to subscribe. Please try again later.');
        }
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="modal-close" onClick={onClose}>×</button>
                <div className='modal-content-items'>
                    <NewsletterLogo title={newsletter.title} logoUrl={newsletter.logo_url} />
                    <p className="modal-title">Discover more from {newsletter.title}.</p>
                    <p className="subscriber-count">Over {newsletter.subscribers_count ?? 0} subscribers</p>
                    <input
                        type="email"
                        placeholder="Type your email..."
                        className="email-input"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {error && <p className="error-message" style={{ color: error === "Success" ? "green" : "red" }}>{error}</p>}
                    {error !== "Success" &&
                        <>
                            <button className="subscribe-button" onClick={subscribe}>Subscribe</button>
                            <button className="continue-reading" onClick={onClose}>
                                Continue reading <span className="arrow">›</span>
                            </button>
                        </>
                    }
                </div>
            </div>
        </div >
    );
};

export default SubscriptionModal;
