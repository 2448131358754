import React, { useState, useEffect } from 'react';
import "../styles/ShareModal.css";
import IssueCard from './IssueCard'; // Import the IssueCard component
import iconEnvelope from "../assets/envelope.png"
import iconLink from "../assets/link.png"
import iconEllipsis from "../assets/ellipsis-horizontal.png"
import iconClose from "../assets/x-circle.png"

const ShareModal = ({ isOpen, onClose, shareUrl, issue = null }) => {
    const [isClipboardSupported, setIsClipboardSupported] = useState(false);
    const [isEmailSupported, setIsEmailSupported] = useState(false);
    const [isShareSupported, setIsShareSupported] = useState(false);

    useEffect(() => {
        // Check if Clipboard API is supported
        if (navigator.clipboard) {
            setIsClipboardSupported(true);
        }

        // Email is generally supported on all devices
        setIsEmailSupported(true);

        // Check if Web Share API is supported
        if (navigator.share) {
            setIsShareSupported(true);
        }
    }, []);

    const handleCopyLink = () => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(shareUrl).then(() => {
                alert("Link copied to clipboard!");
            }).catch(err => {
                console.error("Failed to copy: ", err);
            });
        }
    };

    const handleEmailShare = () => {
        const mailtoLink = `mailto:?subject=Check out this ${issue === null ? "newsletter" : "post"}&body=${shareUrl}`;
        window.location.href = mailtoLink;
    };

    const handleOtherShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: `Check out this ${issue === null ? "newsletter" : "post"}`,
                    text: '',
                    url: shareUrl
                });
            } catch (err) {
                console.error("Error sharing: ", err);
            }
        }
    };

    if (!isOpen) return null;

    return (
        <div className="share-modal-overlay">
            <div className="share-modal-content">
                <div className="share-modal-header-container">
                    <h2 className="share-modal-header">{`Share this ${issue === null ? "newsletter" : "post"}`}</h2>
                    <button className="share-modal-close" onClick={onClose}>
                        <img src={iconClose} alt="Close" className="close-icon" />
                    </button>
                </div>
                {issue && (
                    <IssueCard
                        issue={issue}
                        isInShareModal={true}
                    />
                )}
                <div className="share-modal-options">
                    {isClipboardSupported && (
                        <div className="share-option">
                            <button className="icon-button" onClick={handleCopyLink}>
                                <img src={iconLink} alt="Copy Link" className="icon-image" />
                            </button>
                            <p>Copy Link</p>
                        </div>
                    )}
                    {isEmailSupported && (
                        <div className="share-option">
                            <button className="icon-button" onClick={handleEmailShare}>
                                <img src={iconEnvelope} alt="Email" className="icon-image" />
                            </button>
                            <p>Email</p>
                        </div>
                    )}
                    {isShareSupported && (
                        <div className="share-option">
                            <button className="icon-button" onClick={handleOtherShare}>
                                <img src={iconEllipsis} alt="Other" className="icon-image" />
                            </button>
                            <p>Other</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ShareModal;