// src/components/IssueCard.js
import React from 'react';
import "../styles/IssueCard.css"
import placeholderImage from '../assets/placeholder.png'; // Adjust the import path as needed

const IssueCard = ({ issue, index, onClick, lastIssueElementRef, isInShareModal = false }) => {

    const extractFirstImage = (content) => {
        const imgRegex = /<img.*?src="(.*?)"/;
        const match = content?.match(imgRegex);
        return match ? match[1] : null;
    };

    return (
        <div
            className={`issue-card ${isInShareModal ? 'share-modal-style' : ''}`} // Apply conditional class
            onClick={!isInShareModal && onClick ? () => onClick(issue.podcast_id) : null} // Only apply click handler if not in Share Modal
            ref={index !== undefined && index === lastIssueElementRef ? lastIssueElementRef : null} // Attach ref only when needed
        >
            <div className="issue-image">
                {extractFirstImage(issue.html_content) ? (
                    <img src={extractFirstImage(issue.html_content)} alt={issue.title} />
                ) : (
                    <img src={placeholderImage} alt="Placeholder" className="placeholder-image" />
                )}
            </div>
            <div className="issue-content">
                <h2>{issue.title}</h2>
                <p className="ellipsis">{issue.description}</p>
            </div>
        </div>
    );
};

export default IssueCard;