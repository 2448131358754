import React, { createContext, useContext, useState } from 'react';

const NewsletterContext = createContext();

export const useNewsletter = () => useContext(NewsletterContext);

export const NewsletterProvider = ({ children }) => {
    const [newsletter, setNewsletter] = useState(null);
    const [issues, setIssues] = useState([]);
    const [loading, setLoading] = useState(false);

    const getIssueById = (issueId) => {
        return issues.find(issue => issue.podcast_id === issueId);
    };

    return (
        <NewsletterContext.Provider value={{ newsletter, setNewsletter, issues, setIssues, loading, setLoading, getIssueById }}>
            {children}
        </NewsletterContext.Provider>
    );
};
