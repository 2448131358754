import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Header from './Header';
import IssueContent from './IssueContent';
import SubscriptionModal from './SubscriptionModal';
import '../styles/NewsletterIssue.css';
import ShareModal from './ShareModal'; // Import the ShareModal
import { useNewsletter } from '../utils/NewsletterContext'; // Adjust the import path as needed
import { useApi } from '../utils/apiContext';

const NewsletterIssue = ({ baseURL }) => {
  const navigate = useNavigate();

  const { newsletterUri, issueId } = useParams()
  const { newsletter, setNewsletter, issues, setIssues, loading, setLoading, getIssueById } = useNewsletter();
  const { fetchIssue, fetchNewsletter } = useApi();
  const [showShareModal, setShowShareModal] = useState(false); // State for ShareModal visibility
  const [shareUrl, setShareUrl] = useState(""); // State for dynamic share URL

  const [newsletterUriValue, setNewsletterUriValue] = useState(null)
  const [issueIdValue, setIssueIdValue] = useState(null)

  const [showModal, setShowModal] = useState(false);
  const [issue, setIssue] = useState(null);
  const [error, setError] = useState(null);

  // Check if the "show_share" query parameter is present
  const [queryParams] = useSearchParams();
  const shouldShowShareModal = queryParams.get('show_share') === 'true';
  const newsletterUriQueryParam = queryParams.get('newsletter_uri')
  const issueIdQueryParam = queryParams.get('issue_id')

  useEffect(() => {
    if (!newsletterUri && !issueId && newsletterUriQueryParam && issueIdQueryParam) {
      const url = window.location.href;
      if (url.includes("/dev/")) {
        window.history.replaceState({}, '', '/dev/' + newsletterUriQueryParam + '/' + issueIdQueryParam);
      } else {
        window.history.replaceState({}, '', '/' + newsletterUriQueryParam + '/' + issueIdQueryParam);
      }
      setNewsletterUriValue(newsletterUriQueryParam)
      setIssueIdValue(issueIdQueryParam)
    } else if (newsletterUri && issueId) {
      setNewsletterUriValue(newsletterUri)
      setIssueIdValue(issueId)
    }
  }, [newsletterUri, newsletterUriQueryParam, issueId, issueIdQueryParam]);

  useEffect(() => {
    console.log(newsletterUriValue, issueIdValue)

    if (!baseURL || !newsletterUriValue || !issueIdValue) {
      return
    }

    const loadData = async () => {
      setLoading(true);
      if (!issue) {
        try {
          if (!newsletter) {
            const resultNewsletters = await fetchNewsletter(newsletterUriValue);
            if (resultNewsletters && resultNewsletters.newsletters && resultNewsletters.newsletters.length > 0) {
              if (resultNewsletters.newsletters[0] && !resultNewsletters.newsletters[0].public) {
                navigate("/")
                return
              }
              setNewsletter(resultNewsletters.newsletters[0]);
            } else {
              navigate("/")
              return
            }
          }

          const localIssue = getIssueById(issueIdValue)
          if (!localIssue) {
            const resultIssue = await fetchIssue(newsletterUriValue, issueIdValue)
            if (resultIssue && resultIssue.issues && resultIssue.issues.length > 0) {
              setIssue(resultIssue.issues[0])
              setIssues(prevIssues => [...prevIssues, resultIssue.issues[0]]);
            }
          } else {
            setIssue(localIssue)
          }

          // Set the share URL dynamically for this issue
          setShareUrl(`https://flockletter.com/${newsletterUriValue}/${issueIdValue}`);

          // Automatically show the ShareModal if the query param is present
          if (shouldShowShareModal) {
            setShowShareModal(true);
          }
        } catch (error) {
          console.error('Failed to fetch data:', error);
          setError(error)
          navigate("/")
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    if (!loading) {
      loadData();
    }
  }, [baseURL, newsletterUriValue, issueIdValue, newsletter, setNewsletter, issues, setIssues, loading, setLoading, fetchIssue]);

  useEffect(() => {
    if (issue) {
      const title = `Flock Letter - ${issue.title} in ${newsletter?.title ?? issue.newsletter_data?.title ?? newsletter?.newsletter_uri ?? newsletterUriValue}`
      const description = `View Issue`
      const imageUrl = newsletter.logo_url

      document.title = title;

      // Update meta tags dynamically
      const updateMetaTag = (selector, attribute, value) => {
        let element = document.querySelector(selector);
        if (element) {
          element.setAttribute(attribute, value);
        } else {
          // If the tag doesn't exist, create it
          element = document.createElement('meta');
          element.setAttribute('property', selector.replace('meta[property="', '').replace('"]', ''));
          element.setAttribute(attribute, value);
          document.head.appendChild(element);
        }
      };

      // Update the relevant meta tags
      updateMetaTag('meta[name="title"]', 'content', title);
      updateMetaTag('meta[name="description"]', 'content', description);
      updateMetaTag('meta[property="og:title"]', 'content', title);
      updateMetaTag('meta[property="og:description"]', 'content', description);
      updateMetaTag('meta[property="og:image"]', 'content', imageUrl);
      updateMetaTag('meta[property="twitter:title"]', 'content', title);
      updateMetaTag('meta[property="twitter:description"]', 'content', description);
      updateMetaTag('meta[property="twitter:image"]', 'content', imageUrl);
    }
  }, [issue, newsletter, newsletterUriValue])

  const toggleModal = () => setShowModal(!showModal);
  const toggleShareModal = () => setShowShareModal(!showShareModal); // Function to toggle ShareModal

  if (loading) {
    return <div className="newsletter-loading">Loading...</div>;
  }

  return (
    <div className="newsletter-container">
      <div className="newsletter-sticky-header">
        <Header newsletter={newsletter} newsletterUri={newsletterUriValue} onSubscribe={toggleModal} />
      </div>
      <div className="newsletter-content-wrapper">
        <IssueContent issue={issue} />
      </div>
      {showModal && (
        <SubscriptionModal onClose={toggleModal} newsletter={newsletter} />
      )}
      {showShareModal && issue && shareUrl && (
        <ShareModal
          isOpen={showShareModal}
          onClose={toggleShareModal}
          shareUrl={shareUrl} // Pass the dynamic share URL for this issue
          issue={issue} // Pass the issue object to the modal
        />
      )}
    </div>
  );
};

export default NewsletterIssue;
