import React, { createContext, useContext } from 'react';

const ApiContext = createContext();

export const useApi = () => {
    return useContext(ApiContext);
};

export const ApiProvider = ({ baseURL, children }) => {
    const GET = 'get'
    const POST = 'post'
    const PUT = 'put'
    const DELETE = 'delete'

    function prepareHeaders() {
        let headers = new Headers()

        headers.append('Content-Type', 'application/json')
        headers.append('Accept', 'application/json')

        return headers
    }

    async function fetchData(url, method, headers, body) {
        return await fetch(url, {
            method: method,
            // mode: 'no-cors',
            headers: headers,
            body: body,
        })
            .then(async (data) => {
                if (data.status === 200) {
                    return await data.json()
                } else if (data.status === 400) {
                    return await data.json()
                }
                // else if (data.status === 401) {
                // logout()
                // } 
                else {
                    throw `Failed to fetch data with code: ${data.status}`
                }
            })
            .catch((e) => {
                if (!(e instanceof DOMException)) {
                    throw e.message
                }
            })
    }

    // function cancelRequest(controller) {
    //     if (controller !== undefined && controller !== null) {
    //         controller.abort()
    //     }
    // }

    async function fetchIssue(newsletterUri, issueId) {
        if (baseURL) {
            const url = `${baseURL}/web/issue?newsletter_uri=${newsletterUri}&issue_id=${issueId}`
            return await fetchData(url, GET, prepareHeaders(), null)
        }
    }

    async function fetchNewsletter(newsletterUri) {
        if (baseURL) {
            const url = `${baseURL}/web/newsletter?newsletter_uri=${newsletterUri}`
            return await fetchData(url, GET, prepareHeaders(), null)
        }
    }

    async function fetchIssuesPaginated(newsletterUri, limit, cursor) {
        if (baseURL) {
            const url = `${baseURL}/web/issue?newsletter_uri=${newsletterUri}&limit=${limit}&cursor=${cursor}`
            return await fetchData(url, GET, prepareHeaders(), null)
        }
    }

    async function subscribeToNewsletter(email, newsletterUri) {
        if (baseURL) {
            const url = `${baseURL}/web/newsletter/subscribe`
            return await fetchData(url, POST, prepareHeaders(), JSON.stringify({
                "email": email,
                "newsletter_uri": newsletterUri
            }))
        }
    }

    async function unsubscribeFromNewsletter(email, newsletterUri) {
        if (baseURL) {
            const url = `${baseURL}/web/newsletter/unsubscribe`
            return await fetchData(url, POST, prepareHeaders(), JSON.stringify({
                "email": email,
                "newsletter_uri": newsletterUri
            }))
        }
    }

    const value = {
        fetchIssue,
        fetchNewsletter,
        fetchIssuesPaginated,
        subscribeToNewsletter,
        unsubscribeFromNewsletter,
    }

    return (
        <ApiContext.Provider value={value}>
            {children}
        </ApiContext.Provider>
    );
};