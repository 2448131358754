import React from 'react';
import '../styles/IssueContent.css';

const IssueContent = ({ issue }) => {
    return (
        (issue && <main className={"mainContent"}>
            <h2 className={"articleTitle"}>{issue.title}</h2>
            <div
                className={"articleContent"}
                dangerouslySetInnerHTML={{ __html: issue.html_content }}
            />
        </main>)
    );
};

export default IssueContent;