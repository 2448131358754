import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useApi } from '../utils/apiContext';
import { wait } from '@testing-library/user-event/dist/utils';
import "../styles/UnsubscribePage.css"

const UnsubscribePage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { unsubscribeFromNewsletter } = useApi()
    const [newsletterURI, setNewsletterURI] = useState('');
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const newsletterParam = params.get('newsletter_uri');
        const emailParam = params.get('email');

        if (newsletterParam && emailParam) {
            setNewsletterURI(newsletterParam);
            setEmail(emailParam);
        } else {
            setError('We are missing data. Press Unsubscribe in an email again');
        }
    }, [location.search]);

    const handleUnsubscribe = async () => {
        if (email && newsletterURI) {
            try {
                const result = await unsubscribeFromNewsletter(email, newsletterURI)

                if (result) {
                    if (result.error) {
                        throw new Error('Failed to unsubscribe');
                    } else {
                        setStatus('Successfully unsubscribed. Redirecting...');
                        setError('');
                        await wait(2000)
                        const url = window.location.href;
                        if (url.includes("/dev/")) {
                            navigate(`/dev/newsletter?newsletter_uri=${newsletterURI}`);
                        } else {
                            navigate(`/newsletter?newsletter_uri=${newsletterURI}`)
                        }
                    }
                } else {
                    throw new Error('Failed to unsubscribe');
                }
            } catch (err) {
                setError(err.message);
                setStatus('');
            }
        }
    };

    return (
        <div className="unsubscribe-container">
            {!status && (
                <>
                    <p className="unsubscribe-message">
                        Do you really want to unsubscribe <strong>{email}</strong> from <strong>{newsletterURI}</strong>?
                    </p>
                    <button onClick={handleUnsubscribe} className="unsubscribe-button">
                        Unsubscribe
                    </button>
                </>
            )}
            {status && <p className={`unsubscribe-status ${status === 'Successfully unsubscribed. Redirecting...' ? 'success' : 'error'}`}>{status}</p>}
            {error && <p className="unsubscribe-status error">{error}</p>}

        </div>
    );
};

export default UnsubscribePage;