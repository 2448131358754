import React from 'react';
import { useNavigate } from 'react-router-dom';
import NewsletterLogo from './NewsletterLogo';
import '../styles/Header.css';

const Header = ({ newsletter, newsletterUri, onSubscribe }) => {
    const navigate = useNavigate();

    const titleClicked = () => {
        const url = window.location.href;
        if (url.includes("/dev/")) {
            navigate(`/dev/newsletter?newsletter_uri=${newsletterUri}`);
        } else {
            navigate(`/newsletter?newsletter_uri=${newsletterUri}`);
        }
    };

    return (
        <header className="newsletter-header">
            <div className="newsletter-title-container" onClick={titleClicked}>
                {newsletter &&
                    <>
                        <NewsletterLogo title={newsletter.title} logoUrl={newsletter.logo_url} />
                        <h1 className="newsletter-title">{newsletter.title}</h1>
                    </>
                }
            </div>
            <button className="newsletter-subscribe-button" onClick={onSubscribe}>
                Subscribe
            </button>
        </header>
    );
};

export default Header;