import React, { useState } from 'react';
import '../styles/NewsletterLogo.css';

const NewsletterLogo = ({ title, logoUrl }) => {
    const [imageError, setImageError] = useState(false);

    const handleImageError = () => {
        setImageError(true);
    };

    return (
        <div className={"newsletter-logo"}>
            {logoUrl && !imageError ? (
                <img
                    src={logoUrl}
                    alt={`${title} logo`}
                    onError={handleImageError}
                />
            ) : (
                title && <div className={"newsletter-logo-placeholder"}>{title[0]}</div>
            )}
        </div>
    );
};

export default NewsletterLogo;