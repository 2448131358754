import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import "../styles/NewsletterPage.css";
import Header from './Header';
import SubscriptionModal from './SubscriptionModal';
import ShareModal from './ShareModal'; // Import the ShareModal
import IssueCard from './IssueCard'; // Import the IssueCard component

import { useNewsletter } from '../utils/NewsletterContext'; // Adjust the import path as needed
import { useApi } from '../utils/apiContext';

const NewsletterPage = ({ baseURL }) => {
    const { newsletterUri } = useParams()
    const { fetchIssuesPaginated, fetchNewsletter } = useApi();
    const navigate = useNavigate();
    const { newsletter, setNewsletter, issues, setIssues, loading, setLoading } = useNewsletter();

    const [newsletterUriValue, setNewsletterUriValue] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false); // New state for ShareModal
    const [shareUrl, setShareUrl] = useState(""); // State for dynamic share URL

    const [error, setError] = useState(null);
    const [limit] = useState(10); // Limit for pagination
    const [cursor, setCursor] = useState(null); // Pagination cursor

    const observer = useRef();

    // Check if the "show_share" query parameter is present
    const [queryParams] = useSearchParams();
    const shouldShowShareModal = queryParams.get('show_share') === 'true';
    const newsletterUriQueryParam = queryParams.get('newsletter_uri')

    useEffect(() => {
        if (!newsletterUri && newsletterUriQueryParam) {
            const url = window.location.href;
            if (url.includes("/dev/")) {
                window.history.replaceState({}, '', '/dev/' + newsletterUriQueryParam);
            } else {
                window.history.replaceState({}, '', '/' + newsletterUriQueryParam);
            }
            setNewsletterUriValue(newsletterUriQueryParam)
        } else if (newsletterUri) {
            setNewsletterUriValue(newsletterUri)
        }
    }, [newsletterUri, newsletterUriQueryParam]);

    // Load the initial set of issues
    useEffect(() => {
        if (!baseURL || !newsletterUriValue) {
            return
        }
        const loadInitialData = async () => {
            if (!issues || issues.length === 0) {
                setLoading(true);
            }
            setError(null)
            try {
                const resultNewsletters = await fetchNewsletter(newsletterUriValue);

                if (resultNewsletters && resultNewsletters.newsletters && resultNewsletters.newsletters.length > 0) {
                    if (resultNewsletters.newsletters[0] && !resultNewsletters.newsletters[0].public) {
                        navigate("/")
                        return
                    }
                    setNewsletter(resultNewsletters.newsletters[0]);

                    // Use newsletter_uri directly for the share URL
                    setShareUrl(`https://flockletter.com/${resultNewsletters.newsletters[0].newsletter_uri}`);

                    // Automatically show the ShareModal if the query param is present
                    if (shouldShowShareModal) {
                        setShowShareModal(true);
                    }
                } else {
                    navigate("/")
                    return
                }

                const resultIssues = await fetchIssuesPaginated(newsletterUriValue, limit, null);
                if (resultIssues) {
                    if (resultIssues.error) {
                        navigate("/")
                        return
                    } else {
                        setIssues(resultIssues.issues);
                        setCursor(resultIssues.cursor);
                    }
                }
            } catch (error) {
                setError('Failed to fetch data');
                console.error('Failed to fetch data:', error);
            } finally {
                setLoading(false);
            }
        };

        loadInitialData();
    }, [baseURL, newsletterUriValue, limit, setIssues, setLoading, setNewsletter]);

    // Function to load more issues
    const loadMoreIssues = useCallback(async () => {
        if (!cursor || loading) return;

        setLoading(true);
        try {
            const result = await fetchIssuesPaginated(newsletterUriValue, limit, cursor);
            if (result) {
                if (result.error) {
                    //TODO: - Error handling
                } else {
                    setIssues((prevIssues) => [...prevIssues, ...result.issues]);
                    setCursor(result.cursor);
                }
            }
        } catch (error) {
            setError('Failed to load more issues');
            console.error('Failed to load more issues:', error);
        } finally {
            setLoading(false);
        }
    }, [baseURL, cursor, fetchIssuesPaginated, limit, loading, newsletterUriValue, setIssues]);

    useEffect(() => {
        if (newsletter) {
            const title = `Flock Letter - ${newsletter?.title ?? newsletter?.newsletter_uri ?? newsletterUriValue}`
            const description = `View Newsletter`
            const imageUrl = newsletter.logo_url

            document.title = title;

            // Update meta tags dynamically
            const updateMetaTag = (selector, attribute, value) => {
                let element = document.querySelector(selector);
                if (element) {
                    element.setAttribute(attribute, value);
                } else {
                    // If the tag doesn't exist, create it
                    element = document.createElement('meta');
                    element.setAttribute('property', selector.replace('meta[property="', '').replace('"]', ''));
                    element.setAttribute(attribute, value);
                    document.head.appendChild(element);
                }
            };

            // Update the relevant meta tags
            updateMetaTag('meta[name="title"]', 'content', title);
            updateMetaTag('meta[name="description"]', 'content', description);
            updateMetaTag('meta[property="og:title"]', 'content', title);
            updateMetaTag('meta[property="og:description"]', 'content', description);
            updateMetaTag('meta[property="og:image"]', 'content', imageUrl);
            updateMetaTag('meta[property="twitter:title"]', 'content', title);
            updateMetaTag('meta[property="twitter:description"]', 'content', description);
            updateMetaTag('meta[property="twitter:image"]', 'content', imageUrl);
        }
    }, [newsletter, newsletterUriValue])

    // IntersectionObserver callback
    const lastIssueElementRef = useCallback(
        (node) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && cursor) {
                    loadMoreIssues();
                }
            });
            if (node) observer.current.observe(node);
        },
        [loading, cursor, loadMoreIssues]
    );

    const toggleModal = () => setShowModal(!showModal);
    const toggleShareModal = () => setShowShareModal(!showShareModal); // Function to toggle ShareModal

    if (loading && issues.length === 0) {
        return <div className="newsletter-loading">Loading...</div>;
    }

    const handleCardClick = (issueId) => {
        const url = window.location.href;
        if (url.includes("/dev/")) {
            navigate(`/dev/issue?newsletter_uri=${newsletterUriValue}&issue_id=${issueId}`);
        } else {
            navigate(`/issue?newsletter_uri=${newsletterUriValue}&issue_id=${issueId}`);
        }
    };

    return (
        <div className="newsletter-page">
            <div className="newsletter-sticky-header">
                <Header newsletter={newsletter} newsletterUri={newsletterUriValue} onSubscribe={toggleModal} />
            </div>
            {!loading && issues?.length > 0 ?
                <div className="issues-list">
                    {issues.map((issue, index) => (
                        <IssueCard
                            key={issue.podcast_id}
                            issue={issue}
                            index={index}
                            onClick={handleCardClick}
                            lastIssueElementRef={index === issues.length - 1 ? lastIssueElementRef : null}
                        />
                    ))}
                </div> : <p style={{ textAlign: 'center' }}>No issues published yet</p>
            }

            {showModal && (
                <SubscriptionModal onClose={toggleModal} newsletter={newsletter} />
            )}

            {showShareModal && shareUrl && (
                <ShareModal
                    isOpen={showShareModal}
                    onClose={toggleShareModal}
                    shareUrl={shareUrl} // Pass the dynamic share URL
                />
            )}

            {loading && <div className="newsletter-loading">Loading more issues...</div>}
            {error && <div className="error">{error}</div>}
        </div >
    );
};

export default NewsletterPage;
